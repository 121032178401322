// src/components/Profile.js

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { user } = useAuth0();

  return (
    <div style={styles.container}>
      <h1>Profile Page</h1>
      <img src={user.picture} alt="Profile" style={styles.image} />
      <p><strong>Name:</strong> {user.name}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Roles:</strong> {user['https://api.triptoad.io/roles'].join(', ')}</p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
  },
  image: {
    borderRadius: '50%',
    width: '150px',
    height: '150px',
  },
};

export default Profile;
