// src/components/LodgingLogManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const LodgingLogManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [logs, setLogs] = useState([]);
  const [lodgingType, setLodgingType] = useState('Hotel');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [roomType, setRoomType] = useState('');
  const [notes, setNotes] = useState('');
  const [pricePerNight, setPricePerNight] = useState('');
  const [currency, setCurrency] = useState('USD');

  const fetchLogs = async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`https://api.triptoad.io/trips/${tripId}/lodging`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setLogs(res.data);
  };

  const createLog = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const res = await axios.post(`https://api.triptoad.io/trips/${tripId}/lodging`, {
      lodgingType,
      name,
      address,
      checkInDate,
      checkOutDate,
      confirmationNumber,
      contactInfo,
      roomType,
      notes,
      pricePerNight,
      currency
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 201) {
      // reset fields
      setName('');
      setAddress('');
      setCheckInDate('');
      setCheckOutDate('');
      setConfirmationNumber('');
      setContactInfo('');
      setRoomType('');
      setNotes('');
      setPricePerNight('');
      setCurrency('USD');
      fetchLogs();
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [tripId]);

  return (
    <div>
      <h2>Lodging for Trip {tripId}</h2>
      <form onSubmit={createLog}>
        <select value={lodgingType} onChange={e => setLodgingType(e.target.value)}>
          <option>Hotel</option>
          <option>Hostel</option>
          <option>FriendsFamily</option>
          <option>CruiseCabin</option>
          <option>Airbnb</option>
        </select>
        <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
        <input type="text" placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} />
        <input type="datetime-local" placeholder="Check In Date" value={checkInDate} onChange={e => setCheckInDate(e.target.value)} />
        <input type="datetime-local" placeholder="Check Out Date" value={checkOutDate} onChange={e => setCheckOutDate(e.target.value)} />
        <input type="text" placeholder="Confirmation Number" value={confirmationNumber} onChange={e => setConfirmationNumber(e.target.value)} />
        <input type="text" placeholder="Contact Info" value={contactInfo} onChange={e => setContactInfo(e.target.value)} />
        <input type="text" placeholder="Room Type" value={roomType} onChange={e => setRoomType(e.target.value)} />
        <input type="text" placeholder="Notes" value={notes} onChange={e => setNotes(e.target.value)} />
        <input type="number" step="0.01" placeholder="Price per night" value={pricePerNight} onChange={e => setPricePerNight(e.target.value)} />
        <input type="text" placeholder="Currency" value={currency} onChange={e => setCurrency(e.target.value)} />
        <button type="submit">Add Lodging Log</button>
      </form>

      <ul>
        {logs.map(log => (
          <li key={log.id}>{log.lodgingType} at {log.name}, {log.address}</li>
        ))}
      </ul>
    </div>
  );
};

export default LodgingLogManager;
