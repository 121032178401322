// src/components/ProFeatureButton.js

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Button from '@mui/material/Button'; // Ensure you're using Material-UI

const ProFeatureButton = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const handleAccessProFeature = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log('Access Token:', token); // Log the token
      const response = await axios.get('https://api.triptoad.io/pro-feature', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data);
    } catch (error) {
      console.error('Error accessing Pro Feature:', error);
      if (error.response && error.response.status === 403) {
        alert('Access Denied: You do not have the required permissions.');
      } else {
        alert('Failed to access Pro Feature.');
      }
    }
  };
  

  return (
    isAuthenticated && (
      <Button variant="contained" color="primary" onClick={handleAccessProFeature}>
        Access Pro Feature
      </Button>
    )
  );
};

export default ProFeatureButton;
