// src/components/TripManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const TripManager = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [trips, setTrips] = useState([]);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchTrips = async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get('https://api.triptoad.io/trips', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTrips(res.data);
  };

  const createTrip = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const res = await axios.post('https://api.triptoad.io/trips', {
      name,
      startDate,
      endDate
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 201) {
      setName('');
      setStartDate('');
      setEndDate('');
      fetchTrips();
    }
  };

  useEffect(() => {
    fetchTrips();
  }, []);

  return (
    <div>
      <h2>Create a New Trip</h2>
      <form onSubmit={createTrip}>
        <input type="text" placeholder="Trip Name" value={name} onChange={e => setName(e.target.value)} required />
        <input type="datetime-local" placeholder="Start Date" value={startDate} onChange={e => setStartDate(e.target.value)} required />
        <input type="datetime-local" placeholder="End Date" value={endDate} onChange={e => setEndDate(e.target.value)} required />
        <button type="submit">Create Trip</button>
      </form>

      <h2>Your Trips</h2>
      <ul>
        {trips.map(trip => (
          <li key={trip.id}>
            {trip.name} (from {trip.startDate} to {trip.endDate})
            <br />
            <a href={`/trip/${trip.id}/transportation`}>Manage Transportation</a> | 
            <a href={`/trip/${trip.id}/lodging`}>Manage Lodging</a> |
            <a href={`/trip/${trip.id}/restaurant`}>Manage Restaurants</a> |
            <a href={`/trip/${trip.id}/activity`}>Manage Activities</a> |
            <a href={`/trip/${trip.id}/locations`}>Manage Locations</a> |
            <a href={`/trip/${trip.id}/share`}>Manage Shared Users</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TripManager;
