import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong. {this.state.error.message}</h1>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
