// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import App from "./App";

// Use hardcoded values if environment variables are not loaded
const domain = process.env.REACT_APP_AUTH0_DOMAIN || "auth.triptoad.io";
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "4VCBcpKDQy589XJ5jixWYb2HmlNIXlCa";
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "https://api.triptoad.io";

ReactDOM.render(
    <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
            redirect_uri: window.location.origin + "/callback",
            audience: audience,
            scope: "openid profile email read:data"
        }}
    >
        <Router>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </Router>
    </Auth0Provider>,
    document.getElementById("root")
);
