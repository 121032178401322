// src/App.js

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Profile from './components/Profile';
import Callback from './components/Callback';
import ProFeatureButton from './components/ProFeatureButton';
import TripManager from './components/TripManager';
import TransportationLogManager from './components/TransportationLogManager';
import LodgingLogManager from './components/LodgingLogManager';
import RestaurantLogManager from './components/RestaurantLogManager';
import ActivityLogManager from './components/ActivityLogManager';
import TripLocationManager from './components/TripLocationManager';
import UserTripManager from './components/UserTripManager';
import Loading from './components/Loading';

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(false);

  // Always call hooks at the top level.
  // This hook handles showing a loading screen briefly on route changes.
  useEffect(() => {
    setIsPageLoading(true);
    const timeout = setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [location]);

  // After hooks are defined, we can conditionally return.
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {isPageLoading && <Loading />}
      {!isPageLoading && (
        <>
          <Navbar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={isAuthenticated ? <Profile /> : <Home />} />
              <Route path="/callback" element={<Callback />} />
              <Route path="/pro-feature" element={isAuthenticated ? <ProFeatureButton /> : <Home />} />
              <Route path="/my-trips" element={isAuthenticated ? <TripManager /> : <Home />} />
              <Route path="/trip/:tripId/transportation" element={isAuthenticated ? <TransportationLogManager /> : <Home />} />
              <Route path="/trip/:tripId/lodging" element={isAuthenticated ? <LodgingLogManager /> : <Home />} />
              <Route path="/trip/:tripId/restaurant" element={isAuthenticated ? <RestaurantLogManager /> : <Home />} />
              <Route path="/trip/:tripId/activity" element={isAuthenticated ? <ActivityLogManager /> : <Home />} />
              <Route path="/trip/:tripId/locations" element={isAuthenticated ? <TripLocationManager /> : <Home />} />
              <Route path="/trip/:tripId/share" element={isAuthenticated ? <UserTripManager /> : <Home />} />
            </Routes>
          </main>
        </>
      )}
    </div>
  );
};

export default App;
