import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Callback = () => {
    const { handleRedirectCallback } = useAuth0(); // Note: isAuthenticated isn't necessary here
    const navigate = useNavigate();

    useEffect(() => {
        const processCallback = async () => {
            try {
                await handleRedirectCallback();
                navigate("/profile"); // Redirect to profile page after successful login
            } catch (error) {
                console.error("Error handling the callback:", error);
                navigate("/"); // Redirect to home page on error
            }
        };

        processCallback();
    }, [handleRedirectCallback, navigate]);

    return <h2>Processing login...</h2>;
};

export default Callback;
