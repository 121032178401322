// src/components/ActivityLogManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ActivityLogManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [logs, setLogs] = useState([]);
  const [name, setName] = useState('');
  const [activityDate, setActivityDate] = useState('');
  const [address, setAddress] = useState('');
  const [operatorName, setOperatorName] = useState('');
  const [bookingReference, setBookingReference] = useState('');
  const [notes, setNotes] = useState('');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [pointsUsed, setPointsUsed] = useState('');

  const fetchLogs = async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`https://api.triptoad.io/trips/${tripId}/activity`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setLogs(res.data);
  };

  const createLog = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const res = await axios.post(`https://api.triptoad.io/trips/${tripId}/activity`, {
      name,
      activityDate,
      address,
      operatorName,
      bookingReference,
      notes,
      price,
      currency,
      pointsUsed: pointsUsed ? parseInt(pointsUsed) : null
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 201) {
      setName('');
      setActivityDate('');
      setAddress('');
      setOperatorName('');
      setBookingReference('');
      setNotes('');
      setPrice('');
      setCurrency('USD');
      setPointsUsed('');
      fetchLogs();
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [tripId]);

  return (
    <div>
      <h2>Activities for Trip {tripId}</h2>
      <form onSubmit={createLog}>
        <input type="text" placeholder="Activity Name" value={name} onChange={e => setName(e.target.value)} required />
        <input type="datetime-local" placeholder="Activity Date" value={activityDate} onChange={e => setActivityDate(e.target.value)} />
        <input type="text" placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} />
        <input type="text" placeholder="Operator Name" value={operatorName} onChange={e => setOperatorName(e.target.value)} />
        <input type="text" placeholder="Booking Reference" value={bookingReference} onChange={e => setBookingReference(e.target.value)} />
        <input type="text" placeholder="Notes" value={notes} onChange={e => setNotes(e.target.value)} />
        <input type="number" step="0.01" placeholder="Price" value={price} onChange={e => setPrice(e.target.value)} />
        <input type="text" placeholder="Currency" value={currency} onChange={e => setCurrency(e.target.value)} />
        <input type="number" placeholder="Points Used" value={pointsUsed} onChange={e => setPointsUsed(e.target.value)} />
        <button type="submit">Add Activity</button>
      </form>

      <ul>
        {logs.map(log => (
          <li key={log.id}>{log.name} @ {log.address}</li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityLogManager;
