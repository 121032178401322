// src/components/TransportationLogManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TransportationLogManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [logs, setLogs] = useState([]);
  const [transportType, setTransportType] = useState('Plane');
  const [operatorName, setOperatorName] = useState('');
  // ... other fields like startLocation, endLocation...

  const fetchLogs = async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`https://api.triptoad.io/trips/${tripId}/transportation`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setLogs(res.data);
  };

  const createLog = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const res = await axios.post(`https://api.triptoad.io/trips/${tripId}/transportation`, {
      transportType,
      operatorName,
      // ... other fields
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 201) {
      setOperatorName('');
      // reset other fields...
      fetchLogs();
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [tripId]);

  return (
    <div>
      <h2>Transportation for Trip {tripId}</h2>
      <form onSubmit={createLog}>
        <select value={transportType} onChange={e => setTransportType(e.target.value)}>
          <option>Plane</option>
          <option>Train</option>
          <option>Car</option>
          <option>Ferry</option>
          <option>Cruise</option>
          <option>Walking</option>
          <option>RideShare</option>
        </select>
        <input type="text" placeholder="Operator Name" value={operatorName} onChange={e => setOperatorName(e.target.value)} />
        {/* Add inputs for startLocation, endLocation, departureTime, arrivalTime, etc. */}
        <button type="submit">Add Transportation Log</button>
      </form>

      <ul>
        {logs.map(log => (
          <li key={log.id}>{log.transportType} from {log.startLocation} to {log.endLocation}</li>
        ))}
      </ul>
    </div>
  );
};

export default TransportationLogManager;
