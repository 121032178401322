// src/components/Loading.js
import React from 'react';

const Loading = () => {
  // You can style this more elaborately, add a spinner gif, a CSS animation, etc.
  const spinnerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontSize: '24px',
    fontFamily: 'sans-serif'
  };

  return (
    <div style={spinnerStyle}>
      <div>
        <div className="spinner" style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          border: '6px solid #ccc',
          borderTopColor: '#333',
          animation: 'spin 1s linear infinite'
        }}></div>
        <p>Loading...</p>
        <style>
          {`
            @keyframes spin {
              to { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default Loading;
