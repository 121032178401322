// src/components/RestaurantLogManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const RestaurantLogManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [logs, setLogs] = useState([]);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [cuisineType, setCuisineType] = useState('');
  const [reservationDate, setReservationDate] = useState('');
  const [reservationNumber, setReservationNumber] = useState('');
  const [notes, setNotes] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [mealType, setMealType] = useState('Any');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [pointsUsed, setPointsUsed] = useState('');

  const fetchLogs = async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`https://api.triptoad.io/trips/${tripId}/restaurant`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setLogs(res.data);
  };

  const createLog = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const res = await axios.post(`https://api.triptoad.io/trips/${tripId}/restaurant`, {
      name,
      address,
      cuisineType,
      reservationDate,
      reservationNumber,
      notes,
      contactInfo,
      mealType,
      price,
      currency,
      pointsUsed: pointsUsed ? parseInt(pointsUsed) : null
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 201) {
      setName('');
      setAddress('');
      setCuisineType('');
      setReservationDate('');
      setReservationNumber('');
      setNotes('');
      setContactInfo('');
      setMealType('Any');
      setPrice('');
      setCurrency('USD');
      setPointsUsed('');
      fetchLogs();
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [tripId]);

  return (
    <div>
      <h2>Restaurants for Trip {tripId}</h2>
      <form onSubmit={createLog}>
        <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} required />
        <input type="text" placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} />
        <input type="text" placeholder="Cuisine Type" value={cuisineType} onChange={e => setCuisineType(e.target.value)} />
        <input type="datetime-local" placeholder="Reservation Date" value={reservationDate} onChange={e => setReservationDate(e.target.value)} />
        <input type="text" placeholder="Reservation Number" value={reservationNumber} onChange={e => setReservationNumber(e.target.value)} />
        <input type="text" placeholder="Notes" value={notes} onChange={e => setNotes(e.target.value)} />
        <input type="text" placeholder="Contact Info" value={contactInfo} onChange={e => setContactInfo(e.target.value)} />
        <select value={mealType} onChange={e => setMealType(e.target.value)}>
          <option>Breakfast</option>
          <option>Lunch</option>
          <option>Dinner</option>
          <option>Any</option>
        </select>
        <input type="number" step="0.01" placeholder="Price" value={price} onChange={e => setPrice(e.target.value)} />
        <input type="text" placeholder="Currency" value={currency} onChange={e => setCurrency(e.target.value)} />
        <input type="number" placeholder="Points Used" value={pointsUsed} onChange={e => setPointsUsed(e.target.value)} />
        <button type="submit">Add Restaurant Log</button>
      </form>

      <ul>
        {logs.map(log => (
          <li key={log.id}>{log.name} - {log.cuisineType} @ {log.address}</li>
        ))}
      </ul>
    </div>
  );
};

export default RestaurantLogManager;
