// src/components/TripLocationManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TripLocationManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [locations, setLocations] = useState([]);
  const [locationName, setLocationName] = useState('');
  const [notes, setNotes] = useState('');
  const [order, setOrder] = useState('');

  const fetchLocations = async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`https://api.triptoad.io/trips/${tripId}/locations`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setLocations(res.data);
  };

  const createLocation = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const res = await axios.post(`https://api.triptoad.io/trips/${tripId}/locations`, {
      locationName,
      notes,
      order: order ? parseInt(order) : null
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 201) {
      setLocationName('');
      setNotes('');
      setOrder('');
      fetchLocations();
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [tripId]);

  return (
    <div>
      <h2>Locations for Trip {tripId}</h2>
      <form onSubmit={createLocation}>
        <input type="text" placeholder="Location Name" value={locationName} onChange={e => setLocationName(e.target.value)} required />
        <input type="text" placeholder="Notes" value={notes} onChange={e => setNotes(e.target.value)} />
        <input type="number" placeholder="Order" value={order} onChange={e => setOrder(e.target.value)} />
        <button type="submit">Add Location</button>
      </form>

      <ul>
        {locations.map(loc => (
          <li key={loc.id}>{loc.locationName} - {loc.notes}</li>
        ))}
      </ul>
    </div>
  );
};

export default TripLocationManager;
