// src/components/UserTripManager.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const UserTripManager = () => {
  const { tripId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [sharedUsers, setSharedUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [roleOnTrip, setRoleOnTrip] = useState('viewer');

  const fetchSharedUsers = async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`https://api.triptoad.io/trips/${tripId}/shared-users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setSharedUsers(res.data);
  };

  const shareTrip = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const res = await axios.post(`https://api.triptoad.io/trips/${tripId}/share`, {
      userId: parseInt(userId),
      roleOnTrip
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 201) {
      setUserId('');
      setRoleOnTrip('viewer');
      fetchSharedUsers();
    }
  };

  useEffect(() => {
    fetchSharedUsers();
  }, [tripId]);

  return (
    <div>
      <h2>Share Trip {tripId} with Another User</h2>
      <form onSubmit={shareTrip}>
        <input type="number" placeholder="User ID" value={userId} onChange={e => setUserId(e.target.value)} required />
        <select value={roleOnTrip} onChange={e => setRoleOnTrip(e.target.value)}>
          <option>viewer</option>
          <option>editor</option>
        </select>
        <button type="submit">Share Trip</button>
      </form>

      <h2>Shared Users</h2>
      <ul>
        {sharedUsers.map(u => (
          <li key={u.id}>User ID: {u.id}, Role: {u.UserTrip.roleOnTrip}</li>
        ))}
      </ul>
    </div>
  );
};

export default UserTripManager;
