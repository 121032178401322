// src/components/Navbar.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const Navbar = () => {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

    return (
        <nav style={styles.nav}>
            <h1>Trip Toad</h1>
            <div>
                {isAuthenticated ? (
                    <>
                        <Link to="/profile" style={styles.link}>Profile</Link>
                        <Link to="/pro-feature" style={styles.link}>Pro Feature</Link>
                        <Link to="/my-trips" style={styles.link}>Trip Manager</Link>
                        <button onClick={() => logout({ returnTo: window.location.origin })} style={styles.button}>
                            Logout
                        </button>
                    </>
                ) : (
                    <button onClick={() => loginWithRedirect()} style={styles.button}>Login</button>
                )}
            </div>
        </nav>
    );
};

const styles = {
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: '#282c34',
        color: 'white',
    },
    link: {
        marginRight: '15px',
        color: 'white',
        textDecoration: 'none',
        fontSize: '16px',
    },
    button: {
        padding: '8px 12px',
        fontSize: '16px',
        cursor: 'pointer',
    },
};

export default Navbar;
